.theme-btn {
	background: $theme-primary-color;
	color: $white;
	display: inline-block;
	font-weight: 600;
	padding: 20px 35px;
	padding-right: 65px;
	border: 0;
	border-radius: 6px;

	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 16px;
	font-size: calc-rem-value(16);

	&:before {
		position: absolute;
		right: 30px;
		top: 50%;
		content: "\f102";
		font-family: "Flaticon";
		transform: translateY(-50%);
	}

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		border-radius: 6px;
		width: 100%;
		height: 100%;
		background: darken($theme-primary-color, 8%);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color, 5%);
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
		padding-right: 45px;

		&:before {
			right: 18px;
		}
	}
}

.theme-btn-s2 {
	background: $white;
	color: $text-color;
	display: inline-block;
	padding: 12px 22px;
	border: 1px solid $theme-primary-color;
	border-radius: 55px;


	z-index: 1;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 55px;
		@include linear-gradient-bg(left, #00ded8, #00a7d5);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
	}

	@include media-query(991px) {
		font-size: 14px;
		font-size: calc-rem-value(14);
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color, 5%);
		// @include linear-gradient-bg(left, #00ded8, #00a7d5);
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}
}



.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;



	&:after,
	&:before {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1 {
		background: #ddd;

		&:hover {
			background-color: darken(#ddd, 8%);
		}
	}

}